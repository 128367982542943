<template>
  <!-- eslint-disable -->
  <!--Main Wrapper Start-->
  <div id="top" class="wrapper">
    <!-- Content Section Start -->
    <div class="midd-container">
      <!-- Hero Section Start -->
      <div class="hero-main mercury-layout white-sec" :style="style">
        <div class="container">
          <div class="row align-items-center flex-row-reverse">
            <div class="col-sm-12 col-md-6" data-wow-delay="0.5s">
              <div class="mercury-animation">
                <div class="numbers">
                  <div class="number-one" />
                  <div class="number-two" />
                </div>
                <div id="earth-box-cover">
                  <div class="earth-icons">
                    <i class="icon-1" />
                    <i class="icon-2" />
                    <i class="icon-3" />
                    <i class="icon-4" />
                    <i class="icon-5" />
                    <i class="icon-6" />
                    <i class="icon-7" />
                  </div>
                  <div id="earth-box">
                    <span />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-6 mobile-center">
              <h1>Elros</h1>
              <p class="lead">Designed to withstand any given challenge, Elros thrives even in the most adverse market conditions. Built to be resilient and adaptable, Elros is a crypto powerhouse and ensures long-term success for its trusting investors.</p>
              <div class="hero-btns">
                <a href="https://pancakeswap.finance/swap?outputCurrency=0xE916EFb77F2ec7FCB277E34a51BAB5ff306DB41c" target="_blank" class="btn el-btn mr-md-2 mb-md-2 mb-md-0">Buy Now</a>
                <a href="https://t.me/ElrosCryptoGroup" target="_blank" class="btn el-btn mr-sm-2 mr-md-2 mb-md-2 mb-md-0">Join us</a>
                <router-link v-if="isVip" to="/vip-treasury" class="btn el-btn mr-md-2 mb-md-2 mb-md-0">Vip Treasury</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Hero Section End -->
      <!--About Start -->
      <div id="about" class="about-section style-2 p-tb">
        <div class="container">
          <div class="row">
            <div class="col-sm-12">
              <div class="sec-title"><h1>About Elros</h1></div>
              <h5 class="accent-color">What is Elros?</h5>
              <p class="lead">Our platform empowers investors to generate revenue from various sources and use it further to acquire large-cap cryptocurrencies with low risk. In addition, it enables and facilitates the capital inflow from successful crypto projects and events to token holders. As it is independent of short-term hype or mania, this concept proves its potential to thrive and grow exponentially in a bear market.</p>
              <a class="btn el-btn" href="https://medium.com/@elroscrypto/introducing-the-elros-project-6d6ad010c2f0" target="_blank">Read More</a>
            </div>
          </div>
        </div>
      </div>
      <!--About end -->
      <!-- Benefits Start -->
      <div class="benefits p-tb white-sec">
        <div class="container">
          <div class="sec-title text-center"><h3>Elros Core Pillars <span>to </span>Conquer the Market</h3></div>
          <div class="benefits-box row">
            <div class="col-md-4">
              <div class="bf-icon">
                <img :src="time" alt="">
              </div>
              <h3>Funding Ecosystem</h3>
              <p>Our team provides specialized consulting services and technical support tailored to ensure the success of various projects. The generated commission income funds treasury and rewards token holders, thus ensuring a transparent and rewarding ecosystem for all involved team players.</p>
            </div>
            <div class="col-md-4">
              <div class="bf-icon">
                <img :src="cloudBased" alt="">
              </div>
              <h3>Revenue-Generating Events</h3>
              <p>To boost income, we host revenue-generating events. A small percentage of the total pool is then allocated as rewards to token holders, this way adding to the project value.</p>
            </div>
            <div class="col-md-4">
              <div class="bf-icon">
                <img :src="treasuryIcon" alt="Treasury Icon">
              </div>
              <h3>Treasury Chest</h3>
              <p>Given our ability to generate revenue regardless of market conditions, we can DCA into different large-cap, low-risk crypto projects. This strategic approach positions these investments as highly profitable instruments for long and mid-term returns.</p>
            </div>
          </div>
        </div>
      </div>
      <!-- Benefits End -->
      <!-- Team sec start-->
      <div id="team" class="team-section p-tb">
        <div class="container">
          <div class="team-member text-center">
            <div class="sec-title text-center"><h3>Executive Team</h3></div>
            <div class="sub-txt text-center">
              <p>The Elros team is formed by young, passionate individuals driven by determination and the common desire to succeed. We are working tirelessly to ensure our success and aim to prioritize honesty as well as transparency in every aspect of our project.</p>
            </div>
            <div class="row">
              <div class="col wow fadeInUp">
                <div class="team">
                  <img :src="kd" alt="Team Placeholder">
                  <h4>@TraderKyd</h4>
                  <span>CEO & Dev</span>
                </div>
              </div>
              <div class="col wow fadeInUp" data-wow-delay="0.1s">
                <div class="team">
                  <img :src="bl" alt="Team Placeholder">
                  <h4>@Lacccies</h4>
                  <span>Co-Founder & Advisor</span>
                </div>
              </div>
              <div class="col wow fadeInUp" data-wow-delay="0.3s">
                <div class="team">
                  <img :src="fi" alt="Team Placeholder">
                  <h4>@Fazakasi</h4>
                  <span>CTO & Dev</span>
                </div>
              </div>
              <div class="col wow fadeInUp" data-wow-delay="0.2s">
                <div class="team">
                  <img :src="sp" alt="Team Placeholder">
                  <h4>@StepanZe1</h4>
                  <span>CCO</span>
                </div>
              </div>
            </div>
          </div>
          <div class="team-member text-center">
            <div class="sec-title text-center"><h3>Team</h3></div>
            <div class="row">
              <div class="col wow fadeInUp" data-wow-delay="0.1s">
                <div class="team">
                  <img :src="ati" alt="Team Placeholder">
                  <h4>@moati72</h4>
                  <span>FRTC CEO & Advisor</span>
                </div>
              </div>
              <div class="col wow fadeInUp">
                <div class="team">
                  <img :src="md" alt="Team Placeholder">
                  <h4>@Modee</h4>
                  <span>Producer & Event Organiser</span>
                </div>
              </div>
              <div class="col wow fadeInUp" data-wow-delay="0.2s">
                <div class="team">
                  <img :src="taby" alt="Team Placeholder">
                  <h4>@TaBy93</h4>
                  <span>Community Manager</span>
                </div>
              </div>
              <div class="col wow fadeInUp" data-wow-delay="0.3s">
                <div class="team">
                  <img :src="prinxxe" alt="Team Placeholder">
                  <h4>@prinxxe</h4>
                  <span>Moderator</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Team sec ends -->
      <!-- The Roadmap  start-->
      <div id="roadmap" class="roadmap-sec p-tb white-sec">
        <div class="container">
          <div class="sec-title text-center"><h3>Flexible Roadmap</h3></div>
          <div class="sub-txt text-center">
            <p>Our goal is to serve the best interests of our project and community. We are committed to solving any problems arising from dynamic market conditions, even if it requires adjusting the roadmap to achieve the best results.</p>
          </div>
          <div class="roadmap-slider owl-carousel">
            <div class="item">
              <span>2022 - October</span>
              <p>Concept creation</p>
              <p>Brand creation</p>
              <p>Team development</p>
              <p>LP fundraising</p>
            </div>
            <div class="item">
              <span>2022 - November</span>
              <p>Website and socials creation</p>
              <p>Contract development</p>
              <p>Launch project</p>
              <p>Hold the first event</p>
            </div>
            <div class="item">
              <span>2022 - December</span>
              <p>Referral system applies</p>
              <p>Small organic growth</p>
              <p>More events</p>
              <p>First assets added to treasury</p>
            </div>
            <div class="item">
              <span>2023 - Q1</span>
              <p>Seek for partners</p>
              <p>Organisation of more events</p>
              <p>Hold the first funding round</p>
              <p>Project becomes public</p>
            </div>
            <div class="item">
              <span>2023 - Q2</span>
              <p>Reach 1000 holders</p>
              <p>Apply for Coingecko</p>
              <p>Apply for Coinmarketcap</p>
              <p>Grow treasury beyond the size of LP</p>
            </div>
            <div class="item">
              <span>2023 - Q3</span>
              <p>Generate passive income</p>
              <p>NFT marketplace</p>
              <p>Create more utilities</p>
              <p>More info soon</p>
            </div>
            <div class="item">
              <span>2023 - Q4</span>
              <p>End of vesting phase</p>
              <p>Begin of distribution phase</p>
              <p>Begin of anti-dump strategy</p>
              <p>More info soon</p>
            </div>
          </div>
        </div>
      </div>
      <!-- The Roadmap end-->
      <!-- Brand logo slider -->
      <div id="partners" class="brand-logo-slider c-l p-tb white-sec">
        <div class="container">
          <div class="text-center"><h2 class="section-heading">Our <span>Partners</span></h2></div>
          <carousel
            :autoplay="true"
            :nav="false"
            :dots="false"
            :items="4"
            :responsive="{0:{items:1,nav:false,center:true},600:{items:4,nav:true}}"
            class="partners"
          >
            <div class="item"><a href="https://t.me/CCryptoGemsOfficial" target="_blank"><img :src="cg" alt="Crypto Gems" /></a></div>
            <div class="item"><a href="https://t.me/+20xbyG1vjp9lMjlk" target="_blank"><img :src="theplace" alt="The Place" /></a></div>
            <div class="item"><a href="https://t.me/fairtokencake" target="_blank"><img :src="frtc" alt="FairToken" /></a></div>
            <div class="item"><a href="https://carmel.io" target="_blank"><img :src="carmel" alt="Carmel" /></a></div>
          </carousel>
        </div>
      </div>
      <!-- Brand logo end -->
    </div>
    <!-- Content Section End -->
  </div>
  <!--Main Wrapper End-->
</template>

<script>
  import Vue from 'vue';
  import { mapGetters } from 'vuex';
  import carousel from 'vue-owl-carousel';
  import logo from '@/assets/png/black-gold.png';
  import time from '@/assets/png/time.png';
  import cloudBased from '@/assets/png/eventsicon.png';
  import instantOperations from '@/assets/gif/instant-operations.gif';
  import treasuryIcon from '@/assets/png/treasuryicon.png';
  import backgroundBanner from '@/assets/jpg/banner-1.jpg';
  import teamPlaceholder from '@/assets/jpg/team-placeholder.jpg';
  import brandLogo1 from '@/assets/png/brand-logo1.png';
  import brandLogo2 from '@/assets/png/brand-logo2.png';
  import brandLogo3 from '@/assets/png/brand-logo3.png';
  import brandLogo4 from '@/assets/png/brand-logo4.png';
  import brandLogo5 from '@/assets/png/brand-logo5.png';
  import cg from '@/assets/png/cg-normal.png';
  import theplace from '@/assets/png/theplace-normal.png';
  import frtc from '@/assets/png/frtc-normal.png';
  import carmel from '@/assets/png/carmel.png';
  import bl from '@/assets/png/bl.png';
  import kd from '@/assets/png/kd.png';
  import sp from '@/assets/png/sp.png';
  import fi from '@/assets/png/fi.png';
  import md from '@/assets/png/md.png';
  import hf from '@/assets/png/hf.png';
  import prinxxe from '@/assets/png/prinxxe.png';
  import taby from '@/assets/png/taby-cartoon.png';
  import ati from '@/assets/png/ati.png';
  import { WOW } from 'wowjs';

  export default Vue.extend({
    name: 'elros-home',
    components: { carousel },
    data: () => ({
      logo,
      time,
      instantOperations,
      treasuryIcon,
      cloudBased,
      teamPlaceholder,
      brandLogo1,
      brandLogo2,
      brandLogo3,
      brandLogo4,
      brandLogo5,
      bl,
      kd,
      sp,
      fi,
      md,
      prinxxe,
      cg,
      hf,
      taby,
      theplace,
      frtc,
      carmel,
      ati,
      style: { backgroundImage: `url(${backgroundBanner})` },
    }),
    computed: {
      ...mapGetters({
        isVip: 'session/isVip',
        isMobileView: 'common/isMobileView',
      }),
    },
    mounted() {
      window.initRoadmapCarousel();
      /* eslint-disable */
      const wow = new WOW({
        live: false,
      });
      wow.init();
    },
  });
</script>

<style scoped>
.partners .item {
  align-items: center;
  display: flex;
  justify-content: center;
}
</style>
